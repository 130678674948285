/* You can add global styles to this file, and also import other style files */
@import './mixins';
@import './variables';

:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
  --font-family: "HaboroSans", sans-serif;
  --text-color-white: #fff;
  --bold-font-weight: 700;
  --background-base-color: #fff;

  --inner-content-width: 1015px;

  --content-max-width: 1500px;
  --max-content-width: var(--content-max-width);

  --content-width-col-10: calc(var(--content-max-width) / 12 * 10);
  --content-width-col-8: calc(var(--content-max-width) / 12 * 8);
  --content-width-col-6: calc(var(--content-max-width) / 12 * 6);

  --content-margin: 24px;

  --padding-xs: 50px;
  --padding-sm: 100px;
  --padding-lg: 250px;

  --border-color: var(--text-tint-87);
  --contact-background-color: #EBECED;
  --persons-background-color: rgb(201, 204, 206);
  --footer-background-color: #1C1F21;
  --footer-navbar-background-color: #0f1112;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "HaboroSans";
  src: url("./assets/fonts/haboro-webfont/insigne\ -\ Haboro\ Sans\ Norm\ Regular.otf");
}

@font-face {
  font-family: "HaboroSans";
  font-weight: 700;
  src: url("./assets/fonts/haboro-webfont/insigne\ -\ Haboro\ Sans\ Norm\ Bold.otf");
}

@font-face {
  font-family: "HaboroSans";
  src: url("./assets/fonts/haboro-webfont/insigne\ -\ Haboro\ Sans\ Norm\ Bold\ Italic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "HaboroSans";
  src: url("./assets/fonts/haboro-webfont/insigne\ -\ Haboro\ Sans\ Norm\ Regular\ Italic.otf");
  font-style: italic;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.no-scroll {
  overflow: hidden;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.btn {
  display: inline-block;
  padding: 6px 18px;
  border-radius: 40px;
  font-size: 20px;
  line-height: 36px;
  font-weight: var(--bold-font-weight);
  cursor: pointer;
  border: none;

  @media (min-width: map-get($breakpoints, "phone")) {
    padding: 10px 36px;
    font-size: 24px;
    line-height: 36px;
  }
}

html {
  scroll-behavior: smooth;
}

.links-container {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: end;
  }

  gap: 15px;

  span {
    svg {
      fill: var(--button-text-color);
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 20px;
    line-height: 36px;
    border-radius: var(--border-radius);
    color: var(--button-text-color);
    font-weight: var(--button-font-weight, var(--bold-font-weight));
    fill: var(--button-text-color);
    transition: filter .3s ease-in-out;
  }

  a.stroked {
    padding: 4px 18px;
    border: 2px solid var(--button-text-color);
    background-color: transparent;
  }

  a.icon {
    padding: 1px 18px;
  }

  a:hover {
    filter: brightness(1.1);
  }

  a:not(.stroked) {
    padding: 6px 18px;
    background-color: var(--background-color);
  }

  a.shadow {
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.55);
  }

  a:hover {
    background-color: var(--hover-background-color);
    cursor: pointer;
  }

  @media (min-width: map-get($breakpoints, "phone")) {
    gap: 20px;
    margin-top: 40px;

    span {
      svg {
        scale: 1.2;
      }
    }

    a {
      gap: 18px;
      white-space: nowrap;
    }

    a.stroked {
      padding: 8px 24px;
    }

    a:not(.stroked) {
      padding: 10px 36px;
    }

    a.medium {
      padding: 5px 28px;
      font-size: 20px;
      font-weight: 100;
    }
  }
}

.h3 {
  font-size: 36px;
  line-height: 36px;

  @media (min-width: map-get($breakpoints, "phone")) {
    font-size: 72px;
    line-height: 76px;
  }
}

.h3__subheader {
  font-size: 16px;
  line-height: 22px;

  @media (min-width: map-get($breakpoints, "phone")) {
    font-size: 24px;
    line-height: 32px;
  }
}